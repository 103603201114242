import { EquationLineType } from '@packages/types'
import { createSelector } from 'reselect'

import { bulkOrderRowsSelector, bulkOrderRowCustomizationSelector } from 'customizer/bulkOrder/selectors'
import applyRules from 'customizer/customization/reducer/optimizedApplyRules'
import { customizationSelector } from 'customizer/customization/selectors'

import { eCommercePricingStrategy } from './eCommerce'
import {
  computeExtraPriceForQuestion,
  computeEquationPriceForCustomization,
  getEquationLastQuestionLineIndex,
} from './utils'

const pricingSelector = state => state.pricing
const eCommerceSelector = state => state.eCommerce
const idSelector = (_, { id }) => id

const getPriceSelector = ({ customization, basePrice, extraPrices = {}, equations = [] }) => {
  const extraPricesSum = Object.values(customization.questions).reduce((price, question) => {
    const extraPriceValue = computeExtraPriceForQuestion(question, extraPrices)
    return extraPriceValue ? price + parseFloat(extraPriceValue) : price
  }, 0)

  const equationsSum = equations.reduce((price, equation) => {
    const equationPrice = computeEquationPriceForCustomization(equation, customization)
    return equationPrice ? price + parseFloat(equationPrice) : price
  }, 0)

  return parseFloat(basePrice) + extraPricesSum + equationsSum
}

const getBasePriceFromPriceBreak = (pricing, rows) => {
  const totalQuantity = rows.reduce((totalQuantity, row) => totalQuantity + row.quantity, 0)
  const sortedQuantity = pricing.bulkPrices.sort((a, b) => b.quantity - a.quantity)
  const bulkPrice = sortedQuantity.find(bulkPrice => totalQuantity >= bulkPrice.quantity)

  return bulkPrice?.price || pricing.basePrice
}

export const currencySelector = state => state.pricing.currency

export const customizationPriceSelector = createSelector(
  [customizationSelector, pricingSelector, eCommerceSelector, bulkOrderRowsSelector],
  (customization, pricing, eCommerce, bulkOrderRows) => {
    let customizationPrice = 0

    if (bulkOrderRows.length > 0) {
      customizationPrice = bulkOrderRows.reduce((totalPrice, row) => {
        const itemPrice = getPriceSelector({
          customization: bulkOrderRowCustomizationSelector(customization, row),
          extraPrices: pricing.extraPrices,
          equations: pricing.equations,
          basePrice: pricing.bulkPrices ? getBasePriceFromPriceBreak(pricing, bulkOrderRows) : pricing.basePrice,
        })

        return totalPrice + itemPrice * row.quantity
      }, 0)
    } else {
      customizationPrice = getPriceSelector({
        customization,
        extraPrices: pricing.extraPrices,
        equations: pricing.equations,
        basePrice: pricing.basePrice,
      })
    }

    return eCommercePricingStrategy(eCommerce, pricing).applyPriceModifiers(customizationPrice)
  }
)

export const customizationQuestionPriceSelector = createSelector(
  [idSelector, pricingSelector, eCommerceSelector, customizationSelector],
  (questionId, pricing, eCommerce, customization) => {
    const question = customization.questions[questionId]
    const pricingStrategy = eCommercePricingStrategy(eCommerce, pricing)

    const questionExtraPrices = pricing.extraPrices?.[questionId]
    const questionEquations = pricing.equations?.filter(equation => {
      const index = getEquationLastQuestionLineIndex(equation)
      return (
        equation.options?.visible === true &&
        equation.lines[index].type === EquationLineType.Question &&
        equation.lines[index].value === questionId
      )
    })

    if (!questionExtraPrices && (!questionEquations || questionEquations.length === 0)) return {}

    const pricings = {}

    if (question.showRelativePrice) {
      const selectedPrice = Number(getPriceSelector({ customization, ...pricing }) ?? 0)
      question.answers.forEach(answerId => {
        if (answerId === question.selectedAnswer) {
          delete pricings[answerId]
          return
        }

        const answerPrice = Number(
          getPriceSelector({
            customization: applyRules({
              ...customization,
              questions: {
                ...customization.questions,
                [questionId]: {
                  ...question,
                  selectedAnswer: question.isMultiAnswer ? undefined : answerId,
                  selectedAnswers: question.isMultiAnswer ? [answerId] : undefined,
                },
              },
            }),
            ...pricing,
          }) ?? 0
        )
        const relativePrice = answerPrice - selectedPrice
        if (relativePrice === 0) delete pricings[answerId]
        else pricings[answerId] = pricingStrategy.applyPriceModifiers(relativePrice)
      })

      return pricings
    }

    if (questionExtraPrices) {
      Object.entries(questionExtraPrices).forEach(([answerId, price]) => {
        if (price == 0 || !price) return
        pricings[answerId] = pricingStrategy.applyPriceModifiers(price)
      })
    }

    if (questionEquations && questionEquations.length !== 0) {
      questionEquations.forEach(equation => {
        const currentLine = equation.lines.find(
          line => line.type === EquationLineType.Question && line.value === questionId
        )

        if (!currentLine?.answersMap) return
        Object.keys(currentLine.answersMap).forEach(answerId => {
          if (!question.answers?.includes(answerId)) return

          const simulatedCustomization = {
            answers: customization.answers,
            questions: {
              ...customization.questions,
              [questionId]: {
                ...question,
                selectedAnswer: question.isMultiAnswer ? undefined : answerId,
                selectedAnswers: question.isMultiAnswer ? [answerId] : undefined,
              },
            },
          }

          let simulatedPrice
          if (question.isMultiAnswer && question.selectedAnswers && question.selectedAnswers.length > 0) {
            simulatedCustomization.questions[questionId].selectedAnswers = [...question.selectedAnswers]
            const priceForAllSelectedAnswers = computeEquationPriceForCustomization(equation, simulatedCustomization)

            if (question.selectedAnswers.includes(answerId)) {
              if (equation.options.min && priceForAllSelectedAnswers <= equation.options.min) {
                simulatedPrice = equation.options.min / question.selectedAnswers.length
              } else if (equation.options.max && priceForAllSelectedAnswers >= equation.options.max) {
                simulatedPrice = equation.options.max / question.selectedAnswers.length
              } else {
                simulatedCustomization.questions[questionId].selectedAnswers = question.selectedAnswers.filter(
                  id => id !== answerId
                )

                const equationWithoutMinMax = {
                  ...equation,
                  options: {
                    ...equation.options,
                    min: undefined,
                    max: undefined,
                  },
                }

                const priceWithoutAnswer = computeEquationPriceForCustomization(
                  equationWithoutMinMax,
                  simulatedCustomization
                )
                simulatedPrice = priceForAllSelectedAnswers - priceWithoutAnswer
              }
            } else {
              simulatedCustomization.questions[questionId].selectedAnswers.push(answerId)
              const priceWithAnswer = computeEquationPriceForCustomization(equation, simulatedCustomization)
              simulatedPrice = priceWithAnswer - priceForAllSelectedAnswers
            }
          } else {
            simulatedPrice = computeEquationPriceForCustomization(equation, simulatedCustomization)
          }

          if (simulatedPrice == 0 || !simulatedPrice) return
          if (pricings[answerId]) pricings[answerId] += pricingStrategy.applyPriceModifiers(simulatedPrice)
          else pricings[answerId] = pricingStrategy.applyPriceModifiers(simulatedPrice)
        })
      })
    }

    return pricings
  }
)

import countries from '@packages/data/countries.json'
import {
  BulkOrderCustomizationDesign,
  BulkOrderDesign,
  CustomizationDesign,
  DenormalizedQuote,
  Design,
  ECommerce,
  ProductPricing,
} from '@packages/types'

import { CustomizationState } from 'customizer/customization/reducer/reducer'
import { customizationQuestionPriceSelector } from 'customizer/pricing/selectors'

import { QuestionReference } from './types'

export const defaultPersistenceState = {
  stores: [],
  status: [],
  startDate: undefined,
  endDate: undefined,
  expirationStartDate: undefined,
  expirationEndDate: undefined,
  collectionSize: 0,
  resultSize: 0,
  lastIndex: 0,
  count: 25,
  filter: '',
  sortKey: undefined,
  sortOrder: undefined,
}

export const numberFormatter = (unformattedNumber: string | number | undefined) => {
  if (!unformattedNumber || isNaN(Number(unformattedNumber))) return 0
  return Number(unformattedNumber)
}

const generateCustomizationForDesign = (design: CustomizationDesign) => {
  const questions = {} as { [key: string]: any }
  const answers = {} as { [key: string]: any }

  selectDesignProductionData(design).forEach(item => {
    const { value, values, ref } = item

    if (values && ref.answerIds) {
      ref.answerIds.forEach((answerId, index) => {
        answers[answerId] = { id: answerId, value: values[index] }
      })

      questions[ref.questionId] = {
        id: ref.questionId,
        selectedAnswers: ref.answerIds,
        isMultiAnswer: true,
        answers: ref.answerIds,
      }
    } else {
      answers[ref.answerId] = { id: ref.answerId, value }
      questions[ref.questionId] = { id: ref.questionId, selectedAnswer: ref.answerId }
    }
  })

  return {
    questions,
    answers,
  } as CustomizationState
}

const selectDesignProductionData = (design: CustomizationDesign) => {
  return design?.productionData || []
}

const selectQuoteProductPricingData = (quote: DenormalizedQuote, productId: string) => {
  return (quote?.store?.productsData[productId]?.pricing as ProductPricing) || {}
}

const selectDesignQuestionsPricing = (
  design: CustomizationDesign,
  pricingData: ProductPricing,
  eCommerce: ECommerce
) => {
  const customization = generateCustomizationForDesign(design)
  const questions = {} as { [key: string]: number }
  let basePriceWithQuestions = numberFormatter(pricingData?.basePrice)

  selectDesignProductionData(design).forEach(item => {
    const { ref } = item as { ref: QuestionReference }

    const simulation = (customizationQuestionPriceSelector as any)(
      { customization, pricing: pricingData, eCommerce },
      { id: ref.questionId }
    ) as {
      [key: string]: number
    }

    let priceForQuestion = 0
    if (ref.answerIds) {
      priceForQuestion = ref.answerIds.reduce((total: number, answerId: string) => {
        return total + simulation?.[answerId] ?? 0
      }, 0)
    } else {
      priceForQuestion = simulation?.[ref.answerId] ?? 0
    }

    questions[ref.questionId] = numberFormatter(priceForQuestion ?? 0)
    basePriceWithQuestions += numberFormatter(priceForQuestion ?? 0)
  })

  return {
    questions,
    basePriceWithQuestions,
  }
}

export const selectDesignPricingDetail = (quote: DenormalizedQuote, design: CustomizationDesign) => {
  const pricingData = {
    ...selectQuoteProductPricingData(quote, design.productId),
    currency: quote.currency ?? 'USD',
    applyTaxes: false,
  }

  const pricing = {
    ...selectDesignQuestionsPricing(design, pricingData, quote.store?.eCommerce),
    basePrice: numberFormatter(pricingData?.basePrice),
    totalPrice: numberFormatter(design.price),
  }

  if (pricing.basePriceWithQuestions !== pricing.totalPrice) {
    const diff = pricing.totalPrice - pricing.basePriceWithQuestions

    if (diff < 0) {
      pricing.questions = {}
      pricing.basePrice = pricing.totalPrice
      pricing.basePriceWithQuestions = pricing.totalPrice
    }
  }

  return pricing
}

export const selectQuoteTotalItems = (design: Design) => {
  const total = design
    ? (design as BulkOrderDesign).designs?.reduce(
        (total: number, item: BulkOrderCustomizationDesign) => total + item.quantity,
        0
      )
    : null

  return total
}

export const getCountryName = (countryCode?: string) => {
  return countries.find(country => country.countryShortCode === countryCode)?.countryName
}

import { Asset } from '@packages/types'
import { Image, Link, Text, View, StyleSheet } from '@react-pdf/renderer'
import React from 'react'

import { NEUTRAL_400, PRIMARY_BLUE_600, ExpandIcon } from './assets'

interface PrintableQuoteDesignDetailsProps {
  designImage: Asset
  designUrl?: string
}

const styles = StyleSheet.create({
  section: {
    paddingBottom: 20,
    paddingTop: 20,
    paddingLeft: 40,
    paddingRight: 40,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
  },
  headerTitle: {
    color: NEUTRAL_400,
  },
  designLink: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    color: PRIMARY_BLUE_600,
    gap: '4px',
  },
  imageSection: {
    marginTop: 6,
  },
  designImage: {
    width: '15%',
    height: 'auto',
  },
  redirectIcon: {
    width: '10px',
    height: '10px',
  },
})

const PrintableQuoteDesignDetails = ({ designImage, designUrl }: PrintableQuoteDesignDetailsProps) => {
  return (
    <View style={styles.section}>
      <View style={styles.header}>
        <Text style={styles.headerTitle}>Design details</Text>
        {designUrl && (
          <Link src={designUrl} style={styles.designLink}>
            <Text>Open design</Text>
            <ExpandIcon style={styles} />
          </Link>
        )}
      </View>
      <View style={styles.imageSection}>
        <Image src={designImage.url} style={styles.designImage} />
      </View>
    </View>
  )
}

export default PrintableQuoteDesignDetails

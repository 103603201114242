import { DenormalizedQuote } from '@packages/types'
import React from 'react'

import { Card } from 'common/components'
import { formatDisplayDate } from 'utils/dateUtils'

import QuotesLoadingSkeleton from './QuoteLoadingSkeleton'

interface QuoteExpirationCardProps {
  quote?: DenormalizedQuote
  isLoading: boolean
}

const QuoteExpirationCard = ({ quote, isLoading }: QuoteExpirationCardProps) => (
  <Card className="w-[263px] flex-col">
    <Card.Section className="md:flex-col">
      <span className="text-xs font-medium text-neutral-600 pb-2">Quote expiration</span>
      {!isLoading && quote && quote.expireOn ? (
        <span>{formatDisplayDate(quote.expireOn)}</span>
      ) : (
        <QuotesLoadingSkeleton />
      )}
    </Card.Section>
  </Card>
)

export default QuoteExpirationCard

import { useState } from 'react'

import { DataTablePersistence } from './types/persistence'

const useDataTableState = <TModel>(initialValues: TModel): DataTablePersistence<TModel> => {
  const [state, setState] = useState<TModel>(initialValues)

  const reset = () => setState(initialValues)

  return { state, setState, reset }
}

export default useDataTableState

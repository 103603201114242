import { Field, FieldInputType } from '@packages/types'

import { selectors as customizationSelectors } from 'customizer/customization'
import { actions as designsActions } from 'customizer/designs'
import { AppDispatch, RootState } from 'customizer/store'

import * as types from './actionTypes'
import { emailRegex, phoneRegex } from './constants'

export const openForm = () => ({ type: types.SET_MODAL_VISIBILITY, payload: true })

export const closeForm = () => ({ type: types.SET_MODAL_VISIBILITY, payload: false })

export const updateFormField = (fieldKey: Field['key'], value: string) => ({
  type: types.UPDATE_FORM_FIELD,
  payload: { fieldKey, value },
})

const getFieldError = (field: Field, value?: string) => {
  if (!value && field.required) {
    return 'This field is required'
  } else if (!value) {
    return
  }

  if (field.inputType === FieldInputType.Email && !emailRegex.test(value)) {
    return 'Invalid email address'
  }

  if (field.inputType === FieldInputType.Phone && !Number.isNaN(value) && !phoneRegex.test(value)) {
    return 'Invalid phone number'
  }

  if (field.maxLength && value.length > field.maxLength) {
    return `This field must be less than ${field.maxLength} characters`
  }

  return
}

export const validateFormValue =
  (fieldKey: Field['key'], value?: string) => (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState()
    const field = state.quote.form.fields.find(f => f.key === fieldKey)
    if (!field) return

    const error = getFieldError(field, value)
    return dispatch({
      type: types.SET_FORM_ERRORS,
      payload: { [fieldKey]: error },
    })
  }

export const validateForm = () => (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState()
  const formErrors: Record<string, string | undefined> = {}
  state.quote.form.fields.forEach(field => {
    const fieldError = getFieldError(field, state.quote.formValues[field.key])

    formErrors[field.key] = fieldError
  })

  return dispatch({
    type: types.SET_FORM_ERRORS,
    payload: formErrors,
  })
}

export const submitQuote = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  dispatch(validateForm())
  const state = getState()

  if (state.quote.hasErrors) {
    return
  }
  const isSubmitEnabled = customizationSelectors.isSubmitEnabledSelector(state)
  if (!isSubmitEnabled) {
    return
  }

  if (!state.storeId) {
    return dispatch({ type: types.SET_REQUEST_STATUS, payload: 'success' })
  }

  try {
    dispatch({ type: types.SET_REQUEST_STATUS, payload: 'loading' })

    const quoteFormValues = state.quote.formValues
    const design = await dispatch(designsActions.generateDesign())

    const formData = new FormData()

    formData.append('designId', design.id)
    formData.append('quoteFormValues', JSON.stringify(quoteFormValues))

    const response = await fetch(`${state.baseURL}/brands/${state.tenant}/quotes`, {
      method: 'POST',
      body: formData,
    })

    if (!response.ok) return dispatch({ type: types.SET_REQUEST_STATUS, payload: 'error' })

    const { quoteId } = await response.json()
    dispatch({ type: types.SET_QUOTE_ID, payload: quoteId })

    return dispatch({ type: types.SET_REQUEST_STATUS, payload: 'success' })
  } catch (error: any) {
    return dispatch({ type: types.SET_REQUEST_STATUS, payload: 'error' })
  }
}

export const retry = () => ({ type: types.RETRY })

import { BulkOrderDesign, CustomizationDesign, DenormalizedQuote } from '@packages/types'
import classNames from 'classnames'
import React from 'react'

import { Price, Table } from 'common/components'

import { selectDesignPricingDetail } from '../../utils'

interface QuoteTableBodyProps<DesignType> {
  quote: DenormalizedQuote
  design: DesignType
  isFromBulk?: boolean
}

const QuoteTableBodyBulk = ({ quote, design }: QuoteTableBodyProps<BulkOrderDesign>) => {
  return (
    <Table.Body data-testid="bulk-order-quote-table-body">
      {design.designs.map(({ quantity, design: bulkDesign }, index: number) => {
        const pricing = selectDesignPricingDetail(quote, bulkDesign)
        const priceAdjustment = pricing.totalPrice - pricing.basePriceWithQuestions
        const ajustedBasePrice = pricing.basePrice + priceAdjustment

        return (
          <React.Fragment key={index}>
            <Table.Row className="border-neutral-100 border-t">
              <Table.Cell className="w-6/12 py-3 px-6">
                <div className="flex items-center">
                  <div className="rounded-sm w-9 h-8 mr-3 flex items-center">
                    <img
                      aria-label={`design ${bulkDesign.productId} image`}
                      src={bulkDesign.designImage?.url}
                      className="rounded-sm w-full"
                    />
                  </div>
                  <span className="text-sm font-medium text-neutral-600">Item {index + 1}</span>
                </div>
              </Table.Cell>
              <Table.Cell className="w-2/12 py-3 pl-2">{quantity}</Table.Cell>
              <Table.Cell className="w-2/12 py-3">
                {ajustedBasePrice === 0 ? (
                  <span aria-label="empty base price" className="font-medium text-sm pl-1 text-neutral-300">
                    &mdash;
                  </span>
                ) : (
                  <Price
                    amount={ajustedBasePrice}
                    currency={quote.currency ?? 'USD'}
                    className="font-medium text-sm pl-1"
                  />
                )}
              </Table.Cell>
              <Table.Cell className="w-2/12 text-right py-3 px-6">
                {ajustedBasePrice === 0 ? (
                  <span aria-label="empty base price" className="font-medium text-sm pl-1 text-neutral-300">
                    &mdash;
                  </span>
                ) : (
                  <Price
                    amount={ajustedBasePrice * quantity}
                    currency={quote.currency ?? 'USD'}
                    className="font-medium text-sm pl-1"
                  />
                )}
              </Table.Cell>
            </Table.Row>

            {bulkDesign.productionData?.map(({ key, value, values, ref }, childIndex: number) => {
              const price = pricing.questions[ref.questionId] ?? 0
              return (
                <Table.Row key={`${key}-${childIndex}`} className="border-b-0">
                  <Table.Cell className="w-6/12 py-3 pr-6 pl-10 ">
                    #{childIndex + 1} {key} / {value ? value : values?.join(', ')}
                  </Table.Cell>
                  <Table.Cell className="w-2/12 py-3 pl-2">{quantity}</Table.Cell>
                  <Table.Cell className="w-2/12 py-3">
                    <Price
                      amount={price}
                      currency={quote.currency ?? 'USD'}
                      className={classNames('font-medium text-sm pl-1', {
                        'text-neutral-300': price === 0,
                      })}
                    />
                  </Table.Cell>
                  <Table.Cell className="w-2/12 text-right py-3 px-6">
                    <Price
                      amount={price * quantity}
                      currency={quote.currency ?? 'USD'}
                      className="font-medium text-sm pl-1"
                    />
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </React.Fragment>
        )
      })}
    </Table.Body>
  )
}

const QuoteTableBodyCustomization = ({ quote, design }: QuoteTableBodyProps<CustomizationDesign>) => {
  const pricing = selectDesignPricingDetail(quote, design)
  const priceAdjustment = pricing.totalPrice - pricing.basePriceWithQuestions ?? 0
  const ajustedBasePrice = pricing.basePrice + priceAdjustment

  return (
    <Table.Body data-testid="customization-quote-table-body">
      <Table.Row>
        <Table.Cell className="w-6/12 py-3 px-6">Base price</Table.Cell>
        <Table.Cell className="w-2/12 py-3 pl-2">1</Table.Cell>
        <Table.Cell className="w-2/12 py-3">
          {ajustedBasePrice === 0 ? (
            <span aria-label="empty base price" className="font-medium text-sm pl-1 text-neutral-300">
              &mdash;
            </span>
          ) : (
            <Price amount={ajustedBasePrice} currency={quote.currency ?? 'USD'} className="font-medium text-sm pl-1" />
          )}
        </Table.Cell>
        <Table.Cell className="w-2/12 text-right py-3 px-6">
          {ajustedBasePrice === 0 ? (
            <span aria-label="empty base price" className="font-medium text-sm pl-1 text-neutral-300">
              &mdash;
            </span>
          ) : (
            <Price amount={ajustedBasePrice} currency={quote.currency ?? 'USD'} className="font-medium text-sm pl-1" />
          )}
        </Table.Cell>
      </Table.Row>
      {design.productionData?.map(({ key, value, ref }, index: number) => {
        const price = pricing.questions[ref.questionId] ?? 0
        return (
          <Table.Row key={`${key}-${value}-${index}`}>
            <Table.Cell className="w-6/12 py-3 px-6">
              #{index + 1} {key} / {value}
            </Table.Cell>
            <Table.Cell className="w-2/12 py-3 pl-2">1</Table.Cell>
            <Table.Cell className="w-2/12 py-3">
              <Price
                amount={price}
                currency={quote.currency ?? 'USD'}
                className={classNames('font-medium text-sm pl-1', {
                  'text-neutral-300': price === 0,
                })}
              />
            </Table.Cell>
            <Table.Cell className="w-2/12 text-right py-3 px-6">
              <Price amount={price} currency={quote.currency ?? 'USD'} className="font-medium text-sm pl-1" />
            </Table.Cell>
          </Table.Row>
        )
      })}
    </Table.Body>
  )
}

const QuoteTableBody = ({ quote, design, isFromBulk }: QuoteTableBodyProps<BulkOrderDesign | CustomizationDesign>) => {
  if (isFromBulk) {
    return <QuoteTableBodyBulk quote={quote} design={design as BulkOrderDesign} />
  }

  return <QuoteTableBodyCustomization quote={quote} design={design as CustomizationDesign} />
}

export default QuoteTableBody

import { OnlineStore } from '@packages/types'
import React from 'react'

import { Price, Table } from 'common/components'

interface QuoteTableFooterProps {
  store: OnlineStore
  totalPrice: number
  currency: string
  productId: string
}

const QuoteTableFooter = ({ store, totalPrice, currency, productId }: QuoteTableFooterProps) => {
  const { pricing } = store?.productsData?.[productId] ?? {}
  const taxRate = pricing?.taxes ? parseFloat(pricing.taxes) : undefined

  return (
    <Table.Footer data-testid="quote-table-footer">
      {taxRate ? (
        <Table.FooterRow className="border-t-0" data-testid="quote-tax-row">
          <Table.FooterCell className="py-3 px-6" colSpan={2}>
            Tax
          </Table.FooterCell>
          <Table.FooterCell className="text-lef py-3 px-6 flex justify-between" colSpan={1}>
            <span className="text-sm font-medium text-neutral-600 ">{taxRate}</span>
            <span className="text-sm font-medium text-neutral-600 ">%</span>
          </Table.FooterCell>
          <Table.FooterCell className="text-right py-3 px-6" colSpan={1}>
            <Price
              amount={(totalPrice * taxRate) / 100}
              currency={currency ?? 'USD'}
              className="font-medium text-sm pl-1"
            />
          </Table.FooterCell>
        </Table.FooterRow>
      ) : null}
      <Table.FooterRow className="border-b-0">
        <Table.FooterCell className="text-right py-3 px-2 font-medium" colSpan={3}>
          Total price
        </Table.FooterCell>
        <Table.FooterCell className="text-right  py-3 pr-6 font-medium" colSpan={1}>
          <Price
            amount={taxRate ? totalPrice + (totalPrice * taxRate) / 100 : totalPrice}
            currency={currency ?? 'USD'}
            className="font-medium text-sm pl-1 text-neutral-900"
          />
        </Table.FooterCell>
      </Table.FooterRow>
    </Table.Footer>
  )
}

export default QuoteTableFooter

import { DenormalizedQuote, IndexedQuotes, Quote, QuoteStatus } from '@packages/types'
import qs from 'qs'
import { useEffect } from 'react'
import { useHistory } from 'react-router'

import { trpc } from 'common/hooks/trpc'

import { defaultPersistenceState } from '../utils'

type UseFetchQuoteOptions = {
  persistence?: typeof defaultPersistenceState
  quoteId: string
  baseUrl: string
  isRevision?: boolean
}

export const useFetchQuote = ({ persistence, quoteId, baseUrl, isRevision }: UseFetchQuoteOptions) => {
  const history = useHistory()

  const {
    data,
    hasPreviousPage,
    hasNextPage,
    fetchPreviousPage,
    fetchNextPage,
    isLoading: isLoadingInifiniteQuotes,
    isFetching: isFetchingInifiniteQuotes,
  } = trpc.quote.list.useInfiniteQuery(
    { ...persistence },
    {
      enabled: !!persistence,
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      initialCursor: persistence?.lastIndex,
      getPreviousPageParam: currentPage => {
        const { lastIndex, resultSize } = currentPage.pagination
        const firstIndex = lastIndex - resultSize - persistence!.count
        return firstIndex >= 0 ? firstIndex : undefined
      },
      getNextPageParam: currentPage => {
        const { lastIndex, collectionSize } = currentPage.pagination
        return lastIndex < collectionSize ? lastIndex : undefined
      },
    }
  )

  const { data: directQuote, isLoading: isLoadingDirectQuote } = trpc.quote.getById.useQuery(quoteId)

  const isLoadingQuotes = persistence ? isLoadingInifiniteQuotes : isLoadingDirectQuote

  let quoteSiblings: IndexedQuotes = {}
  let quote: Quote | DenormalizedQuote | undefined

  const handleGoBack = () => {
    if (persistence) {
      const currentPageIndex = (data?.pages || []).findIndex(page => page.results.find(({ id }) => id === quoteId))
      const lastIndex = data?.pageParams?.[currentPageIndex] || persistence.lastIndex
      const query = qs.stringify({ ...persistence, lastIndex }, { encode: false, skipNulls: true })
      history.push(`${baseUrl}/quotes?${query}`)
    } else {
      history.push(`${baseUrl}/quotes`)
    }
  }

  if (persistence) {
    const allQuotes = (data?.pages || []).reduce<DenormalizedQuote[]>(
      (result: any, page) => [...result, ...page.results],
      []
    )
    const currentPosition = allQuotes.findIndex(({ id }) => id === quoteId)

    quoteSiblings = {
      previous: allQuotes[currentPosition - 1],
      next: allQuotes[currentPosition + 1],
    }
    quote = directQuote as DenormalizedQuote | undefined
  } else {
    quote = directQuote as DenormalizedQuote | undefined
  }

  useEffect(() => {
    if (!quoteSiblings.previous && hasPreviousPage) fetchPreviousPage()
    if (!quoteSiblings.next && hasNextPage) fetchNextPage()
  })

  if (quote && isRevision && quote.status === QuoteStatus.Open) {
    quote.status = QuoteStatus.Draft
    const quoteIdParts = quote.quoteId.split('-')
    quote.quoteId = `${quoteIdParts[0]}-${Number(quoteIdParts[1]) + 1}`
    // set edition mode
  }

  return {
    quote,
    quoteSiblings,
    isLoadingQuotes,
    isFetchingQuotes: isFetchingInifiniteQuotes,
    handleGoBack,
  }
}

import { DenormalizedQuote, ECommerce, QuoteStatus } from '@packages/types'
import { pdf } from '@react-pdf/renderer'
import { useQuery } from '@tanstack/react-query'
import React, { MouseEvent } from 'react'
import { useHistory, useParams } from 'react-router'

import { useBrandService } from 'cms/brands'
import { Button, IconButton, Modal, Popover, useModal, usePopover, useToast } from 'common/components'
import { ToastType } from 'common/components/toast/types'
import { trpc } from 'common/hooks/trpc'
import { withFlag } from 'common/users/components'
import NavVerticalIcon from 'icons/bold/01-Interface Essential/03-Menu/navigation-menu-vertical.svg'
import downloadContent from 'utils/downloadContent'

import PrintableQuote from '../PrintableQuote/PrintableQuote'
import BusinessInfoMissingModal from './BusinessInfoMissingModal'

export interface QuoteHeaderActionsProps {
  quote: DenormalizedQuote
}

const QuoteHeaderActions = ({ quote }: QuoteHeaderActionsProps) => {
  const history = useHistory()
  const params = useParams<{ brandName?: string }>()
  const baseUrl = params.brandName ? `/brands/${params.brandName}` : ''
  const dropdownMenu = usePopover({ placement: 'bottom-end', offsetConfig: 10 })
  const trpcContext = trpc.useContext()
  const brandService = useBrandService()

  const businessInfoMissingModal = useModal()
  const confirmCancelModal = useModal()

  const { openToast, openGenericErrorToast } = useToast()

  const { data: brand, isLoading: isBrandLoading } = useQuery(
    brandService.fetchAccount.queryKeys,
    brandService.fetchAccount
  )

  const { mutate: convertToOrder, isLoading } = trpc.quote.convertToOrder.useMutation({
    onSuccess: () => {
      if (quote.store.eCommerce === ECommerce.Shopify) {
        openToast(`Draft order was successfully created in Shopify`, ToastType.success)
      } else {
        openToast(`Quote QT${quote.quoteId} was successfully converted to order`, ToastType.success)
      }

      trpcContext.quote.getById.invalidate()
    },
    onError: () => openGenericErrorToast(`Failed to convert quote QT${quote.quoteId} to order.`),
  })

  const { mutateAsync: updateQuote } = trpc.quote.update.useMutation({
    onSuccess: () => {
      trpcContext.quote.list.invalidate()
      trpcContext.quote.getById.invalidate(quote.id)
    },
  })

  const downloadQuotePDF = async () => {
    const fileName = `QT${quote.quoteId}.pdf`
    const blob = await pdf(<PrintableQuote quote={quote} infos={brand?.business} logo={brand?.logo} />).toBlob()
    downloadContent(blob, 'application/pdf', fileName)
    dropdownMenu.close()
  }

  const cancelQuote = async () => {
    await updateQuote({ id: quote.id, status: QuoteStatus.Canceled })
    dropdownMenu.close()
    confirmCancelModal.close()
    openToast(`Quote QT${quote.quoteId} was successfully cancelled`, ToastType.success)
  }

  const handleMenuClickConvertToOrder: React.MouseEventHandler<HTMLButtonElement> = async () => {
    convertToOrder({ id: quote.id })
  }

  const handleDownloadPdfClick = () => {
    if (!brand?.business?.name || !brand?.business?.email) {
      businessInfoMissingModal.open()
      dropdownMenu.close()
    } else {
      downloadQuotePDF()
    }
  }

  const handleMenuClickCancel = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    confirmCancelModal.open()
    dropdownMenu.close()
  }

  const handleMenuClickReviseQuote = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    history.push(`${baseUrl}/quotes/${quote.id}/revise`)
  }

  const handleMenuClickMarkAsSent = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    await updateQuote({ id: quote.id, sent: !quote.sent })
    openToast(`Quote QT${quote.quoteId} was ${!quote.sent ? 'marked' : 'unmarked'} as sent`, ToastType.success)
    dropdownMenu.close()
  }

  return (
    <div className="flex space-x-3 print:hidden">
      {quote.status === QuoteStatus.Open && !quote.integration?.shopify?.draftOrderId && (
        <Button onClick={handleMenuClickConvertToOrder} disabled={isLoading} isLoading={isLoading}>
          {quote.store.eCommerce === ECommerce.Shopify ? 'Generate Shopify order' : 'Convert to order'}
        </Button>
      )}

      <Button variant="primary" onClick={handleDownloadPdfClick} disabled={isBrandLoading} isLoading={isBrandLoading}>
        Download PDF
      </Button>

      <IconButton aria-label="open actions menu" Icon={NavVerticalIcon} {...dropdownMenu.referenceProps} />
      <Popover {...dropdownMenu.floatingProps} isOpen={dropdownMenu.isOpen}>
        {quote.status === QuoteStatus.Open && (
          <>
            <Popover.Action className="text-neutral-800" onClick={handleMenuClickMarkAsSent}>
              {!quote.sent ? 'Mark as sent' : 'Unmark as sent'}
            </Popover.Action>
            <Popover.Action className="text-neutral-800" onClick={handleMenuClickReviseQuote}>
              Revise quote
            </Popover.Action>
          </>
        )}
        {![QuoteStatus.Canceled, QuoteStatus.Accepted].includes(quote.status) && (
          <Popover.Action className="text-error-default" onClick={handleMenuClickCancel}>
            Cancel quote
          </Popover.Action>
        )}
      </Popover>
      {businessInfoMissingModal.isVisible && (
        <BusinessInfoMissingModal
          onDownloadClick={downloadQuotePDF}
          onAddInfoClick={() => {
            history.push(`${baseUrl}/business`)
          }}
          onClose={businessInfoMissingModal.close}
          onBackdropClick={businessInfoMissingModal.close}
          {...businessInfoMissingModal.modalProps}
        />
      )}
      {confirmCancelModal.isVisible && (
        <Modal onBackdropClick={confirmCancelModal.close} {...confirmCancelModal.modalProps}>
          <Modal.CloseButton onClick={confirmCancelModal.close} />
          <Modal.Title>Cancel quote</Modal.Title>
          <Modal.Details>Are you sure you want to cancel this quote?</Modal.Details>
          <Modal.Actions>
            <Button onClick={confirmCancelModal.close}>No</Button>
            <Button variant="error" onClick={cancelQuote}>
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </div>
  )
}

export default withFlag({
  feature: 'quote_phase_2',
  Component: QuoteHeaderActions,
})

import { QuoteStatus, OrderStatus } from '@packages/types'
import { useFlags } from 'flagsmith/react'
import React, { useMemo } from 'react'

import { Tag } from 'common/components'
import { trpc } from 'common/hooks/trpc'

interface SideMenuCountProps {
  menuType: 'quotes' | 'orders' | 'total'
}

const SideMenuCount = ({ menuType }: SideMenuCountProps) => {
  const flags = useFlags(['quote_phase_1'])

  const { data: pendingOrdersCount } = trpc.order.count.useQuery({
    status: OrderStatus.Pending,
  })

  const { data: openQuotesCount } = trpc.quote.count.useQuery(
    {
      status: QuoteStatus.Open,
    },
    {
      enabled: flags.quote_phase_1.enabled,
    }
  )

  const count = useMemo(() => {
    switch (menuType) {
      case 'quotes':
        return openQuotesCount || 0
      case 'orders':
        return pendingOrdersCount || 0
      case 'total':
        return (openQuotesCount || 0) + (pendingOrdersCount || 0)
      default:
        return 0
    }
  }, [menuType, openQuotesCount, pendingOrdersCount])

  if (count === 0) return null

  return (
    <Tag className="ml-auto mr-4 -mt-0.5 bg-neutral-100 text-neutral-300 font-p4" data-testid={`${menuType}-count`}>
      {count}
    </Tag>
  )
}

export default SideMenuCount

import { BulkOrderDesign, CustomizationDesign, DenormalizedQuote, DesignType } from '@packages/types'
import ReactPDF, { Text, View } from '@react-pdf/renderer'
import React from 'react'

import { selectDesignPricingDetail } from 'cms/quotes/utils'

import Price from './Price'

interface PrintableQuoteTableBodyProps<DesignType> {
  quote: DenormalizedQuote
  design: DesignType
  styles: ReactPDF.Styles
}

const TableBodyBulk = ({ design, quote, styles }: PrintableQuoteTableBodyProps<BulkOrderDesign>) => {
  return (
    <View>
      <View style={styles.tableRow}>
        <View style={[styles.tableCol, styles.tableColDescription, styles.tableColProductTitle]}>
          <Text style={styles.productTitle}>{design.productName}</Text>
        </View>
        <View style={[styles.tableCol, styles.tableColProductTitle]}></View>
        <View style={[styles.tableCol, styles.tableColProductTitle]}></View>
        <View style={[styles.tableCol, styles.tableColProductTitle]}></View>
      </View>
      {design.designs.map(({ quantity, design: bulkDesign }, index: number) => {
        const pricing = selectDesignPricingDetail(quote, bulkDesign)
        const priceAdjustment = pricing.totalPrice - pricing.basePriceWithQuestions

        return (
          <View key={index} wrap={false}>
            <View style={styles.tableRow}>
              <View style={[styles.tableColInnerItems, styles.tableColDescription, styles.tableColInnerItemBigger]}>
                <Text>{`Item ${index + 1}`}</Text>
              </View>
              <View style={styles.tableColInnerItems}>
                <Text>{quantity}</Text>
              </View>
              <View style={styles.tableColInnerItems}>
                <Price amount={pricing.basePrice + priceAdjustment} currency={quote.currency ?? 'USD'} />
              </View>
              <View style={[styles.tableColInnerItems, styles.tableColRightAlign]}>
                <Price amount={(pricing.basePrice + priceAdjustment) * quantity} currency={quote.currency ?? 'USD'} />
              </View>
            </View>
            {bulkDesign.productionData?.map(({ key, value, values, ref }, childIndex: number) => {
              const price = pricing.questions[ref.questionId]
              return (
                <View style={[styles.tableRow]} key={`${key}-${childIndex}`}>
                  <View style={[styles.tableColInnerItems, styles.tableColDescription]}>
                    <Text
                      style={[styles.extraPriceTitle, styles.extraPriceTitleBulk]}
                    >{`${key} / ${value ? value : values?.join(', ')}`}</Text>
                  </View>
                  <View style={styles.tableColInnerItems}>
                    <Text>{quantity}</Text>
                  </View>
                  <View
                    style={!price ? [styles.tableColInnerItems, styles.tableColNoPrice] : styles.tableColInnerItems}
                  >
                    <Price amount={price} currency={quote.currency ?? 'USD'} />
                  </View>
                  <View style={[styles.tableColInnerItems, styles.tableColRightAlign]}>
                    <Price amount={price * quantity} currency={quote.currency ?? 'USD'} />
                  </View>
                </View>
              )
            })}
          </View>
        )
      })}
    </View>
  )
}

const TableBodyCustomization = ({ design, quote, styles }: PrintableQuoteTableBodyProps<CustomizationDesign>) => {
  const pricing = selectDesignPricingDetail(quote, design)
  const priceAdjustment = pricing.totalPrice - pricing.basePriceWithQuestions ?? 0

  return (
    <View>
      <View style={styles.tableRow}>
        <View style={[styles.tableCol, styles.tableColDescription, styles.tableColProductTitle]}>
          <Text style={styles.productTitle}>{design.productName}</Text>
        </View>
        <View style={[styles.tableCol, styles.tableColProductTitle]}>
          <Text>1</Text>
        </View>
        <View style={[styles.tableCol, styles.tableColProductTitle]}>
          <Price amount={pricing.basePrice + priceAdjustment} currency={quote.currency ?? 'USD'} />
        </View>
        <View style={[styles.tableCol, styles.tableColRightAlign, styles.tableColProductTitle]}>
          <Price amount={pricing.basePrice + priceAdjustment} currency={quote.currency ?? 'USD'} />
        </View>
      </View>
      {design.productionData?.map(({ key, value, ref }, index: number) => {
        const price = pricing.questions[ref.questionId]
        return (
          <View key={`${key}-${index}`}>
            <View style={styles.tableRow}>
              <View style={[styles.tableColInnerItems, styles.tableColDescription]}>
                <Text style={styles.extraPriceTitle}>{`${key} / ${value}`}</Text>
              </View>
              <View style={styles.tableColInnerItems}>
                <Text>1</Text>
              </View>
              <View style={!price ? [styles.tableColInnerItems, styles.tableColNoPrice] : styles.tableColInnerItems}>
                <Price amount={price} currency={quote.currency ?? 'USD'} />
              </View>
              <View style={[styles.tableColInnerItems, styles.tableColRightAlign]}>
                <Price amount={price} currency={quote.currency ?? 'USD'} />
              </View>
            </View>
          </View>
        )
      })}
    </View>
  )
}

const PrintableQuoteTableBody = ({
  quote,
  design,
  styles,
}: PrintableQuoteTableBodyProps<BulkOrderDesign | CustomizationDesign>) => {
  const isFromBulk = design.type === DesignType.BulkOrderDesign

  if (isFromBulk) {
    return <TableBodyBulk design={design} quote={quote} styles={styles} />
  }
  return <TableBodyCustomization design={design} quote={quote} styles={styles} />
}

export default PrintableQuoteTableBody

import { DenormalizedQuote, Design, DesignType } from '@packages/types'
import React from 'react'

import { Card } from 'common/components'

import QuoteCardHeader from './QuoteCardHeader'
import QuoteTable from './QuoteTable'

interface QuoteCardProps {
  quote?: DenormalizedQuote
  design?: Design
  isLoading?: boolean
  baseUrl: string
}

const QuoteCard = ({ quote, design, isLoading, baseUrl }: QuoteCardProps) => {
  const isFromBulk = design?.type === DesignType.BulkOrderDesign

  return (
    <Card className="w-full flex flex-col flex-1  mr-8 print:shadow-[none] pb-4">
      <div className="print:clear-both print:border print:border-neutral-100 print:rounded-xl">
        <QuoteCardHeader design={design} isLoading={isLoading} isFromBulk={isFromBulk} baseUrl={baseUrl} />
        <Card.Separator />
        <Card.Section className="p-0">
          <QuoteTable quote={quote} design={design} isLoading={isLoading} isFromBulk={isFromBulk} />
        </Card.Section>
      </div>
    </Card>
  )
}

export default QuoteCard

import { DenormalizedQuote } from '@packages/types'
import React from 'react'

import { Card } from 'common/components'

import QuotesLoadingSkeleton from './QuoteLoadingSkeleton'

interface QuoteDetailsCardProps {
  quote?: DenormalizedQuote
  isLoading: boolean
}

const QuoteDetailsCard = ({ quote, isLoading }: QuoteDetailsCardProps) => (
  <Card className="w-[263px] flex-col">
    <Card.Section className="md:flex-col">
      <span className="text-xs font-medium text-neutral-600 pb-2">Customer name</span>
      {!isLoading && quote ? <span>{quote.customer?.name}</span> : <QuotesLoadingSkeleton />}
    </Card.Section>
    <Card.Separator />
    <Card.Section className="md:flex-col">
      <span className="text-xs font-medium text-neutral-600 pb-2">Email</span>
      {!isLoading && quote ? <span>{quote.customer?.email}</span> : <QuotesLoadingSkeleton />}
    </Card.Section>
    <Card.Separator />
    <Card.Section className="md:flex-col">
      <span className="text-xs font-medium text-neutral-600 pb-2">Message</span>
      {isLoading ? (
        <QuotesLoadingSkeleton />
      ) : quote?.formResult?.message ? (
        <span className="max-h-[200px] overflow-y-auto">{quote.formResult.message}</span>
      ) : (
        <span className="text-neutral-300">No message</span>
      )}
    </Card.Section>
  </Card>
)

export default QuoteDetailsCard

import { AnswerType, EntityType, OverflowBehavior, PartType, TextVerticalAlign } from '@packages/types'
import { get, set, map } from 'lodash'

import { utils as printAreaUtils } from 'common/printAreas'
import { utils as customizationUtils } from 'customizer/customization'
import isNullOrEmpty from 'utils/isNullOrEmpty'

const isPartEditable = (part, view) => {
  return (
    part.printArea?.productPreview.designView === view && Object.values(part.allowedTransforms || {}).includes(true)
  )
}

const getQuestionSelectedAnswer = question => {
  if (!question) return undefined

  return question.selectedAnswer
}

export const answerToView = (entity, view) => {
  const answer = entity?.entityType === EntityType.Answer ? entity : getQuestionSelectedAnswer(entity)

  if (!answer) return

  if ([AnswerType.PrintAreaLogoPosition, AnswerType.PrintAreaTextPosition].includes(answer.type))
    return { type: answer.type, ...answer.position }

  return answer.views?.[view]
}

const multiAnswerToView = (question, view) => {
  const answers = question?.selectedAnswers

  if (!answers) return

  return map(answers, answer => {
    return { id: answer.id, ...answer.views[view] }
  })
}

const getAnswerImageUrls = entity => {
  const answer = entity?.entityType === EntityType.Answer ? entity : getQuestionSelectedAnswer(entity)

  return answer?.views?.map(({ image }) => image?.url).filter(url => url != null)
}

const getTextPlaceholder = question => {
  if (customizationUtils.stepHasQuestionWithAvailableAnswers(question))
    return { type: 'text', text: question.placeholder }
}

const textQuestionToView = (question, view) => {
  const answerView = answerToView(question, view)
  return isNullOrEmpty(answerView?.text) ? getTextPlaceholder(question) : answerView
}

const getPostProcessingOperationsFromModifiers = (modifiers = {}, printAreaMasks, view) => {
  const postProcessingOperations = []

  const masks = printAreaMasks ?? modifiers.masks

  if (masks?.length > 0)
    postProcessingOperations.push({
      type: 'mask',
      nodesIds: masks,
    })

  if (modifiers.blendMode === 'multiply')
    postProcessingOperations.push({
      type: 'globalCompositeOperation',
      value: 'multiply',
    })

  if (modifiers.neon)
    postProcessingOperations.push({
      type: 'neon',
    })

  if (modifiers.engraving?.enabled)
    postProcessingOperations.push({
      type: 'engraving',
      lightDirection: modifiers.engraving?.lightDirections?.[view] ?? { x: 0, y: 0 },
      depth: modifiers.engraving.depth,
      sharpness: modifiers.engraving.sharpness,
    })

  return postProcessingOperations
}

const partialPartToLayer = (part, printArea, view) => {
  return {
    id: part.id,
    partId: part.id,
    highlightGroup: part.highlightGroup,
    forceHighlight: part.forceHighlight,
    filters: part.modifiers?.filters ?? [],
    postProcessingOperations: getPostProcessingOperationsFromModifiers(
      part.modifiers,
      printArea?.productPreview?.masks,
      view
    ),
    focusable: part.focusable,
  }
}

const imagePartToLayer = (part, dimensions, view) => {
  const { isMultiAnswer } = part.image
  const materialView = answerToView(part.material, view)
  const images = getAnswerImageUrls(part.image)

  if (isMultiAnswer) {
    const partialLayer = {
      ...partialPartToLayer(part),
      type: PartType.Image,
      ...transformDrawingProps(materialView),
      scale: dimensions.scale ?? 1,
      images,
    }

    return multiAnswerToView(part.image, view).map(imageView => ({
      ...partialLayer,
      id: imageView.id,
      ...transformDrawingProps(imageView),
    }))
  }

  return {
    ...partialPartToLayer(part),
    name: part.image.name,
    type: PartType.Image,
    ...transformDrawingProps(answerToView(part.image, view)),
    ...transformDrawingProps(materialView),
    scale: dimensions.scale ?? 1,
    images,
  }
}

export const textPartToLayer = (part, dimensions, view) => {
  const textView = textQuestionToView(part.text, view)
  const colorView = answerToView(part.color, view)
  const fontView = answerToView(part.font, view)
  const fontSizeView = answerToView(part.fontSize, view)
  const positionView = answerToView(part.position, view)
  const outlineView = answerToView(part.outline, view)

  if (!part.printArea && positionView.hidden) return

  const isResizable = !!part.allowedTransforms?.resize
  const positionFromDrawingProps = transformDrawingProps(positionView)

  const position = {
    ...positionFromDrawingProps,
    verticalAlign: isResizable
      ? TextVerticalAlign.Middle
      : positionFromDrawingProps.verticalAlign ?? TextVerticalAlign.Middle,
    overflowBehavior: isResizable ? OverflowBehavior.Visible : positionFromDrawingProps.overflowBehavior,
  }

  return {
    ...partialPartToLayer(part, part.printArea, view),
    name: part.text.name,
    scale: dimensions.scale ?? 1,
    clippingGroup: part.printArea?.id,
    type: PartType.Text,
    text: transformDrawingProps(textView),
    font: {
      ...transformDrawingProps(fontView),
      ...transformDrawingProps(fontSizeView),
    },
    color: transformDrawingProps(colorView),
    position: position,
    outline: transformDrawingProps(outlineView),
    width: dimensions.width,
    height: dimensions.height,
    editable: part.editable || isPartEditable(part, view),
    allowedTransforms: part.allowedTransforms,
  }
}

const logoPartToLayer = (part, dimensions, view) => {
  const imageView = answerToView(part.logo, view)
  const positionView = answerToView(part.position, view)

  if (!part.printArea && positionView.hidden) return

  return {
    ...partialPartToLayer(part, part.printArea),
    scale: dimensions.scale ?? 1,
    name: part.logo.name,
    clippingGroup: part.printArea?.id,
    type: PartType.Image,
    editable: part.editable || isPartEditable(part, view),
    allowedTransforms: part.allowedTransforms,
    allowOversize: part.printArea != null,
    image: {
      src: part.logo?.placeholder?.url,
      preventResize: part.logo?.placeholder?.isNotFromCDN,
    },
    ...transformDrawingProps(imageView),
    ...transformDrawingProps(positionView),
  }
}

export const partToLayer = (part, dimensions, view) => {
  switch (part.type) {
    case PartType.Image:
      return imagePartToLayer(part, dimensions, view)
    case PartType.Text:
      return textPartToLayer(part, dimensions, view)
    case PartType.Logo:
      return logoPartToLayer(part, dimensions, view)
  }
}

export const printAreaToClippingGroup = (printArea, globalScale, currentView, isShown) => {
  const printAreaView = printArea.productPreview.views[currentView]

  if (printAreaView.hidden) return

  const printAreaInPixels = printAreaUtils.convertToPixels(printArea)

  const scale = printAreaView.scale / printArea.productPreview.views[printArea.productPreview.designView].scale

  return {
    id: printArea.id,
    printAreaId: printArea.id,
    name: printArea.name,
    type: 'clippingGroup',
    x: printAreaView.x,
    y: printAreaView.y,
    groupScale: scale,
    scale: globalScale,
    width: printAreaInPixels.width * printAreaView.scale,
    height: printAreaInPixels.height * printAreaView.scale,
    outlineColor: printArea.productPreview.outlineColor,
    margins: {
      horizontal: printAreaInPixels.margins.horizontal * printAreaView.scale,
      vertical: printAreaInPixels.margins.vertical * printAreaView.scale,
    },
    bleed: printAreaInPixels.bleed * printAreaView.scale,
    rotation: printAreaView.rotation,
    showPrintArea: printArea.productPreview.isShown && isShown === true,
    showOutline: printArea.productPreview.isShown,
    editable: printArea.editable,
    focusable: printArea.editable,
    objects: [],
  }
}

export const multiAnswerImageToGroup = id => ({
  id: `${id}-group`,
  type: 'multiAnswerGroup',
  objects: [],
})

const TRANSFORMS = {
  logo: {
    'logo.url': 'image.src',
  },
  logoPosition: {
    x: 'position.x',
    y: 'position.y',
    rotation: 'position.rotation',
    maxWidth: 'boundingBox.width',
    maxHeight: 'boundingBox.height',
  },
  printAreaLogoPosition: {
    x: 'position.x',
    y: 'position.y',
    rotation: 'position.rotation',
    maxWidth: 'boundingBox.width',
    maxHeight: 'boundingBox.height',
  },
  image: {
    'image.url': 'image.src',
  },
  material: {
    color: (color, { disableColor }) => ({
      key: 'color.hex',
      value: disableColor == true ? undefined : color,
    }),
    lighting: (lighting, { lightThreshold, lightIntensity }) => ({
      key: 'lighting',
      value:
        lighting == false
          ? null
          : {
              lightThreshold,
              lightIntensity,
            },
    }),
    texture: 'texture',
  },
  text: {
    text: 'value',
  },
  position: {
    shape: 'shape',
    bezier: 'bezier',
    x: 'x',
    y: 'y',
    rotation: 'rotation',
    textAlign: 'textAlign',
    verticalAlign: 'verticalAlign',
    maxWidth: 'boundingBox.maxWidth',
    maxHeight: 'boundingBox.maxHeight',
    overflowBehavior: 'overflowBehavior',
  },
  printAreaTextPosition: {
    shape: 'shape',
    bezier: 'bezier',
    x: 'x',
    y: 'y',
    rotation: 'rotation',
    textAlign: 'textAlign',
    verticalAlign: 'verticalAlign',
    maxWidth: 'boundingBox.maxWidth',
    maxHeight: 'boundingBox.maxHeight',
    overflowBehavior: 'overflowBehavior',
  },
  color: {
    color: 'hex',
  },
  fontSize: {
    size: 'size',
  },
  outline: {
    color: 'hex',
    width: 'width',
  },
  font: {
    font: 'family',
    assets: 'assets',
    fontType: 'fontType',
  },
}

const transformDrawingProps = (drawingProp = {}) => {
  const transformedProp = {}
  const transformObj = TRANSFORMS[drawingProp.type]

  if (!transformObj) return transformedProp
  Object.keys(transformObj).forEach(key => {
    const val = get(drawingProp, key)

    if (val == null) return

    if (typeof transformObj[key] === 'string') {
      return set(transformedProp, transformObj[key], val)
    }

    const { key: transformedKey, value } = transformObj[key](val, drawingProp)

    set(transformedProp, transformedKey, value)
  })

  return transformedProp
}

import { DenormalizedQuote, ECommerce, ShopifyOnlineStore } from '@packages/types'
import React from 'react'

import QuoteShopifyDraftOrderCard from './QuoteShopifyDraftOrderCard'

export type QuoteHeaderIntegrationSectionProps = {
  quote: DenormalizedQuote
}

type QuoteWithShopifyStore = Omit<DenormalizedQuote, 'store'> & { store: ShopifyOnlineStore }

const QuoteHeaderIntegrationSection = ({ quote }: QuoteHeaderIntegrationSectionProps) => {
  if (quote.store.eCommerce === ECommerce.Shopify)
    return <QuoteShopifyDraftOrderCard quote={quote as QuoteWithShopifyStore} />

  return null
}

export default QuoteHeaderIntegrationSection

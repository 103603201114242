import { Plan } from '@packages/types'
import React from 'react'

import { Card } from 'common/components'
import CheckList from 'common/components/CheckList'

interface PlanCardProps {
  plan: Plan
}

const PlanCard = ({ plan }: PlanCardProps) => {
  return (
    <div className="flex flex-col">
      <Card className="h-[401px] w-[410px] mb-3 relative flex flex-col justify-between">
        <Card.Section className="md:flex-col">
          <h1>{plan.name}</h1>
          <span className="w-fit text-3xl font-medium pt-4 bg-gradient-blue bg-clip-text text-transparent active:text-transparent">
            {plan.transactionFeePercentage}%
          </span>
          <span className="text-sm pt-1 font-medium">per successful sale</span>
          <span className="font-normal pt-4 w-full pb-6 text-neutral-600">{plan.description}</span>
          <CheckList items={plan.attributesText} className="fill-primary-500" />
        </Card.Section>
      </Card>
    </div>
  )
}

export default PlanCard

import { CSSProperties } from 'react'

export const colorPickerStyle = {
  picker: {
    width: 'auto',
    background: 'none',
    boxShadow: 'none',
    padding: '0',
  } as CSSProperties,
  Saturation: {
    circle: {
      width: '16px',
      height: '16px',
      boxShadow: '0 0 0 1.5px #fff, inset 0 0 1px 1px rgba(0,0,0,.3),\n            0 0 1px 2px rgba(0,0,0,.4)',
      borderRadius: '50%',
      cursor: 'hand',
      transform: 'translate(-8px, -8px)',
    },
  } as CSSProperties,
}

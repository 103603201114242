import { DenormalizedQuote } from '@packages/types'
import ReactPDF, { View, Text } from '@react-pdf/renderer'
import React from 'react'

import Price from './Price'

interface PrintableQuoteTableFooterProps {
  quote: DenormalizedQuote
  styles: ReactPDF.Styles
}

const PrintableQuoteTableFooter = ({ quote, styles }: PrintableQuoteTableFooterProps) => {
  const { pricing } = quote.store?.productsData?.[quote.items[0].design.productId] ?? {}
  const taxRate = pricing?.taxes ? parseFloat(pricing.taxes) : undefined

  return (
    <View wrap={false}>
      <View style={styles.tableRow}>
        <View style={[styles.tableCol, styles.tableColDescription]}></View>
        <View style={styles.tableCol}></View>
        <View style={styles.tableCol}>
          <Text>Subtotal</Text>
        </View>
        <View style={[styles.tableCol, styles.tableColRightAlign]}>
          <Price amount={quote.totalPrice} currency={quote.currency ?? 'USD'} />
        </View>
      </View>
      {taxRate && (
        <View style={styles.tableRow}>
          <View style={[styles.tableColInnerItems, styles.tableColDescription]}></View>
          <View style={styles.tableColInnerItems}></View>
          <View style={styles.tableColInnerItems}>
            <Text>{`Tax (${taxRate}%)`}</Text>
          </View>
          <View style={[styles.tableColInnerItems, styles.tableColRightAlign]}>
            <Price amount={(quote.totalPrice * taxRate) / 100} currency={quote.currency ?? 'USD'} />
          </View>
        </View>
      )}
      <View style={styles.tableRow}>
        <View style={[styles.tableCol, styles.tableColDescription]}></View>
        <View style={styles.tableCol}></View>
        <View style={[styles.tableCol, styles.totalPrice]}>
          <Text>Total</Text>
        </View>
        <View style={[styles.tableCol, styles.totalPrice, styles.tableColRightAlign]}>
          <Price
            amount={taxRate ? quote.totalPrice + (quote.totalPrice * taxRate) / 100 : quote.totalPrice}
            currency={quote.currency ?? 'USD'}
          />
        </View>
      </View>
    </View>
  )
}

export default PrintableQuoteTableFooter

import { DenormalizedQuote, Design } from '@packages/types'
import React from 'react'

import { Table } from 'common/components'

import QuoteTableBody from './QuoteTableBody'
import QuoteTableFooter from './QuoteTableFooter'

interface QuoteTableProps {
  quote?: DenormalizedQuote
  design?: Design
  isLoading?: boolean | undefined
  isFromBulk: boolean
}

const QuoteTable = ({ quote, design, isLoading, isFromBulk }: QuoteTableProps) => {
  if (isLoading || !design || !quote) {
    return (
      <div className="w-full pl-6 py-2 pr-2">
        <Table>
          <Table.BodyLoader numberOfColumns={4} numberOfRows={10} />
        </Table>
      </div>
    )
  }

  return (
    <Table data-testid="quote-summary">
      <Table.Header className="h-11">
        <Table.HeaderRow>
          <Table.HeaderCell className="w-6/12 py-3 px-6">
            <span className="font-medium text-neutral-600">DETAILS</span>
          </Table.HeaderCell>
          <Table.HeaderCell className="w-2/12">
            <span className="font-medium text-neutral-600">QTY</span>
          </Table.HeaderCell>
          <Table.HeaderCell className="w-2/12">
            <span className="font-medium text-neutral-600">UNIT PRICE</span>
          </Table.HeaderCell>
          <Table.HeaderCell className="w-2/12 text-right px-6">
            <span className="font-medium text-neutral-600">AMOUNT</span>
          </Table.HeaderCell>
        </Table.HeaderRow>
      </Table.Header>

      <QuoteTableBody quote={quote} design={design} isFromBulk={isFromBulk} />
      <QuoteTableFooter
        store={quote.store}
        currency={quote.currency}
        totalPrice={quote.totalPrice || 0}
        productId={design.productId}
      />
    </Table>
  )
}

export default QuoteTable

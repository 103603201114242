import { useSpringRef, useTransition } from '@react-spring/web'
import { createFlagsmithInstance } from 'flagsmith'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import Skater from 'img/skater.svg'

import BulkFeature from './BulkFeature'
import EquationFeature from './EquationFeature'
import InventoryFeature from './InventoryFeature'

export interface LoginContainerProps {
  children: React.ReactNode
}

const flagsmith = createFlagsmithInstance()

const LoginContainer = ({ children }: LoginContainerProps) => {
  const intervalRef = useRef<NodeJS.Timeout>()
  const [Slides, setSlides] = useState([EquationFeature, InventoryFeature])

  const [index, setIndex] = useState(0)

  const switchSlide = useCallback(() => setIndex(index => index + 1), [setIndex])

  const handleVisibilityChange = useCallback(() => {
    if (document.visibilityState === 'visible') {
      intervalRef.current = setInterval(switchSlide, 6000)
    } else {
      if (intervalRef.current) clearInterval(intervalRef.current)
    }
  }, [])

  const transRef = useSpringRef()
  const transitions = useTransition(index, {
    ref: transRef,
    keys: null,
    initial: { transform: 'translate(0%,0)' },
    from: { transform: 'translate(100%,0%)' },
    enter: { transform: 'translate(0%,0)' },
    leave: { transform: 'translate(-100%,0)' },
  })

  useEffect(() => {
    transRef.start()
  }, [index])

  useEffect(() => {
    flagsmith.init({ environmentID: FLAGSMITH_KEY }).then(() => {
      if (flagsmith.hasFeature('bulk_order_login')) setSlides([EquationFeature, InventoryFeature, BulkFeature])
    })
  }, [])

  useEffect(() => {
    if (Slides.length < 2) return

    intervalRef.current = setInterval(switchSlide, 6000)

    window.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current)
      window.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [Slides.length, switchSlide])

  return (
    <div className="flex w-full justify-between h-screen">
      <div className="hidden lg:block lg:w-[50vw] xl:w-[44vw] h-full rounded-r-xl min-h-[47rem] relative overflow-hidden">
        <img src={Skater} className="h-8 w-8 invert absolute left-10 top-8 z-10" />

        {transitions((style, i) => {
          const Slide = Slides[i % Slides.length]
          return (
            <Slide
              style={{
                ...style,
                position: 'absolute' as React.CSSProperties['position'],
                top: 0,
                width: '100%',
              }}
            />
          )
        })}
      </div>
      <div className="flex-1 flex justify-center lg:justify-normal px-8 lg:px-32 xl:pl-32 items-center h-full">
        {children}
      </div>
    </div>
  )
}

export default LoginContainer

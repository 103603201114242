import { createSelector } from 'reselect'

import { RootState } from 'cms/store'

const productBuilderSelector = (state: RootState) => state.productBuilder
const propsSelector = (_: RootState, props: { id?: string | null }) => props

type types = 'groups' | 'answers' | 'rules' | 'questions' | 'parts' | 'printAreas'

type RemovePlural<S extends string> = S extends `${infer S}s` ? `${S}` : never

const createCustomizerProductEntitySelectors = <T extends types, R extends RootState['productBuilder']>(
  type: T,
  extendProductBuilderSelector?: (state: RootState['productBuilder']) => R
) => {
  type ReturnTypeOrRootState<T> = T extends (...args: any[]) => any ? ReturnType<T> : never

  type ProductBuilder = ReturnTypeOrRootState<typeof extendProductBuilderSelector>

  type BaseSelector = `${T}Selector`
  type ArraySelector = `${T}AsArraySelector`
  type ByIdSelector = `${RemovePlural<T>}ByIdSelector`

  type EntitySelector = (s: RootState, props: { id?: string | null }) => ProductBuilder[T][string] | undefined

  type RE<M> = {
    [K in keyof M]: K extends BaseSelector
      ? (s: RootState) => ProductBuilder[T]
      : K extends ArraySelector
      ? (s: RootState) => ProductBuilder[T][string][]
      : K extends ByIdSelector
      ? (s: RootState, props: { id?: string | null }) => ProductBuilder[T][string]
      : never
  }

  const extendedProductBuilderSelector = extendProductBuilderSelector
    ? createSelector(
        productBuilderSelector,
        extendProductBuilderSelector as (state: RootState['productBuilder']) => ProductBuilder
      )
    : productBuilderSelector

  const entitySelector: EntitySelector = createSelector(
    extendedProductBuilderSelector,
    propsSelector,
    (productBuilder, props) => (props.id ? (productBuilder[type][props.id] as ProductBuilder[T][string]) : undefined)
  )

  const selectors = {
    [`${type}Selector` as BaseSelector]: createSelector(
      extendedProductBuilderSelector,
      productBuilder => productBuilder[type]
    ),
    [`${type}AsArraySelector`]: createSelector(extendedProductBuilderSelector, productBuilder =>
      Object.values(productBuilder[type])
    ),
    [`${type.slice(0, -1)}ByIdSelector`]: entitySelector,
  } as Record<
    BaseSelector | ArraySelector | ByIdSelector,
    | ((s: RootState) => ProductBuilder[T])
    | ((s: RootState) => ProductBuilder[T][string][])
    | ((s: RootState, props: { id?: string | null }) => ProductBuilder[T][string] | undefined)
  >

  return selectors as RE<typeof selectors>
}

export default createCustomizerProductEntitySelectors

import { DenormalizedQuote } from '@packages/types'
import React from 'react'

import { isCustomStore, isShopify } from 'cms/onlineStores/utils'
import { Card, Tag } from 'common/components'
import { withFlag } from 'common/users/components'

import QuotesLoadingSkeleton from './QuoteLoadingSkeleton'

interface QuoteStoreCardProps {
  quote?: DenormalizedQuote
  isLoading: boolean
}

const QuoteStoreCard = ({ quote, isLoading }: QuoteStoreCardProps) => (
  <Card>
    <Card.Section className="flex-row items-center w-[263px] space-x-2 justify-between">
      {!isLoading && quote ? (
        <>
          <div className="flex flex-col space-y-1 overflow-hidden">
            <span className="text-sm font-medium text-neutral-900">{quote.store.name}</span>
            <span className="text-xs font-medium text-neutral-400 text-ellipsis overflow-hidden whitespace-nowrap">
              {isShopify(quote.store) || isCustomStore(quote.store) ? quote.store.domain : quote.store.url}
            </span>
          </div>
          <div className="basis-8 shrink-0 ml-auto">
            <Tag className="bg-neutral-100 text-neutral-600 text-[10px] mt-0.5 ml-1">{quote.store.currency}</Tag>
          </div>
        </>
      ) : (
        <QuotesLoadingSkeleton />
      )}
    </Card.Section>
  </Card>
)

export default withFlag({
  feature: 'quote_phase_2',
  Component: QuoteStoreCard,
})
